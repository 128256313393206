<template>
    <div class="upload">
        <label class="form">
            <input type="file" class="file" @change="upload"
                   accept="video/*, audio/*, image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"/>
            <div class="row flex">
                <div class="col-3">
                    <input v-model="title" placeholder="Название"/>
                </div>
                <div class="col-3">
                    <input v-model="number" placeholder="Номер входящего\исходящего"/>
                </div>
                <div class="col-3" v-if="doc_type == 1">
                    <div class="appeal-labels">
                        <label class="appeal-label">
                            <input type="checkbox" :checked="isAppeal" v-model="isAppeal"/>
                            <div class="t">
                                Обращение?
                            </div>
                        </label>
                        <label class="appeal-label">
                            <input type="checkbox" :checked="isAppealRequest" v-model="isAppealRequest"/>
                            <div class="t">
                                Запрос на ознакомление с материалами дела?
                            </div>
                        </label>
                    </div>
                </div>
                <div class="col-3">
                    <div :class="['btn', 'blue', 'inb', {loading:loading}]">
                        {{ loading ? 'Загружаем...' : 'Загрузить' }}
                    </div>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: "uploadFile",
    data() {
        return {
            file: false,
            title: '',
            number: '',
            loading: false,
            isAppeal: false,
            isAppealRequest: false,
        }
    },
    props: [
        'parent_type',
        'parent_id',
        'doc_type'
    ],
    methods: {
        upload() {
            this.loading = true;
            this.file = this.$el.querySelector('input').files[0];
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('type', this.$props.doc_type);
            formData.append('title', this.title);
            formData.append('number', this.number);
            if (this.isAppeal) {
                formData.append('isAppeal', this.isAppeal);
            }
            if (this.isAppealRequest) {
                formData.append('isAppealRequest', this.isAppealRequest);
            }
            this.$api.post(`${this.$props.parent_type}/${this.$props.parent_id}/file_general`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.loading = false;
                if (res.data.success) {
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Файл успешно загружен',
                        type: 'success'
                    });
                    this.title = '';
                    this.number = '';
                    this.$emit('uploaded');
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.upload {
    .file {
        display: none;
    }

    .btn {
        transition: .2s;

        &.loading {
            opacity: 0.5;
        }
    }
}

.appeal-labels {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .appeal-label {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
</style>