<template>
    <table class="fileList table" v-if="list.length">
        <thead>
            <tr>
                <th>Название</th>
                <th>Дата загрузки</th>
                <th>Номер вход\исход</th>
                <th>Операции</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in list" :class="{ 'red' : item.approve == 0, 'green': item.approve == 1}">
                <td>{{item.title}}</td>
                <td class="date">{{item.date}}</td>
                <td>{{item.number}}</td>
                <td class="control flex-st">
                    <div class="btn sm blue" @click="open(item)">Открыть</div>
                    <div class="btn sm red" v-if="($store.state.isAdmin() || $store.state.isImplementor()) && item.type_id == 4 && item.approve != 1" @click="del(item)">Удалить</div>
                    <div class="btn sm green" v-if="$store.state.isAdmin() && item.type_id == 4 && item.approve != 1" @click="approve(item)">Одобрить</div>
                    <div class="btn sm red"   v-if="$store.state.isAdmin() && item.type_id == 4 && item.approve != 0" @click="decline(item)">Отклонить</div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "fileList",
    props:{
        list: Array,
    },
    methods: {
        del(file) {
            this.$api.delete(`${file.parent_type}/${file.parent_id}/file/${file.id}`).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Удалено',content:'Файл успешно удален',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        approve(file) {
            this.$api.post(`${file.parent_type}/${file.parent_id}/file/${file.id}/approve`).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Одобрено',content:'Файл одобрен',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        decline(file) {
            this.$api.post(`${file.parent_type}/${file.parent_id}/file/${file.id}/decline`).then(res => {
                if (res.data.success) {
                    this.$emit('updated');
                    this.$store.commit('showNotify',{title:'Отклонено',content:'Файл отклонен',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        open(file) {
            this.$api.get(`${file.parent_type}/${file.parent_id}/file/${file.id}`).then(res => {
                if (res.data.success) {
                    const linkSource = `data:${res.data.data.mime_type};base64,${res.data.data.base64}`;
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = `${file.parent_type}-${file.parent_id}-${file.id}-${res.data.data.name}`;
                    downloadLink.click();
                    downloadLink.remove();
                    this.$emit('updated');
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.fileList {
    tr {
        &.red {
            background: lighten($cl-red, 30);
        }
        &.green {
            background: lighten($cl-green, 50);
        }
        td,th {
            padding: 5px 20px;
            vertical-align: top;
            &.date {
                min-width: 92px;
            }
        }
    }
    .control {
        display: flex;
        flex-wrap: wrap;
        gap:10px;
    }
}
</style>